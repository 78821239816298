import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { Group, User } from '@models';

export interface GroupState extends EntityState<Group> {
  isLoading: boolean;
  group: Group[];
  total: number;
  dissociateUser: User[];
}
export const groupAdapter = createEntityAdapter<Group>();
export const groupInitialState: GroupState = groupAdapter.getInitialState({
  isLoading: false,
  group: [],
  dissociateUser: [],
  total: 0,
});
