import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GroupState, groupAdapter } from './group.state';
import { Group } from '@models';

export const GROUP_STATE_NAME = 'group';
const selectGroupState = createFeatureSelector<GroupState>(GROUP_STATE_NAME);
const groupSelector = groupAdapter.getSelectors();
const selectGroups = createSelector(selectGroupState, groupSelector.selectAll);
const selectGroupEntities = createSelector(
  selectGroupState,
  groupSelector.selectEntities
);
const selectGroupLoading = createSelector(
  selectGroupState,
  state => state.isLoading
);
const selectGroupById = (id: Group['id']) =>
  createSelector(selectGroupEntities, groups => (groups ? groups[id] : null));

const selectDissociateMember = createSelector(
  selectGroupState,
  state => state.dissociateUser
);

const selectTotal = createSelector(selectGroupState, ({ total }) => total);
export default {
  selectGroupById,
  selectGroupEntities,
  selectGroupLoading,
  selectGroupState,
  selectGroups,
  selectTotal,
  selectDissociateMember,
};
